










import Vue from 'vue'
import { Prop, Component } from 'vue-property-decorator'
import { INotificationParams } from '~/store/modal'

@Component
export default class NotificationAlert extends Vue {
    @Prop({ type: Object }) notification: INotificationParams

    backgroundColor = '#E63C3C'

    REMOVE_NOTIFICATION(notificationToClose: INotificationParams) {
        this.$store.commit('modal/REMOVE_NOTIFICATION', notificationToClose)
    }

    handleScroll(event: any) {
        this.$emit('handleScroll', event.pageY)
    }

    created() {
        if (this.notification.name === 'success') this.backgroundColor = '#1DCF75'
        window.addEventListener('scroll', this.handleScroll)
    }

    destroyed() {
        window.removeEventListener('scroll', this.handleScroll)
    }
}
