var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "notification" }, [
    _c(
      "div",
      {
        staticClass: "notification__icon",
        style: { backgroundColor: _vm.backgroundColor },
      },
      [
        _c("icon", {
          attrs: {
            name: _vm.notification.name === "error" ? "error" : "success",
          },
        }),
      ],
      1
    ),
    _c("div", { staticClass: "notification__right-container" }, [
      _c("div", {
        staticClass: "notification__title",
        domProps: { textContent: _vm._s(_vm.notification.title) },
      }),
      _c("div", {
        staticClass: "notification__content",
        domProps: { textContent: _vm._s(_vm.notification.content) },
      }),
    ]),
    _c(
      "div",
      {
        staticClass: "notification__close",
        on: {
          click: function ($event) {
            return _vm.REMOVE_NOTIFICATION(_vm.notification)
          },
        },
      },
      [_c("icon", { attrs: { name: "close", width: "16", height: "16" } })],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }